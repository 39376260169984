import './ContentResourceInfo.scss';
import { getProvidersProps } from 'dhub-config';
import PropTypes from 'prop-types';
import React from 'react';

const providersLang = getProvidersProps(['lang']);
const providersUrls = getProvidersProps(['urls']);

/**
 * This component renders a container with information of a resource.
 */
function ContentResourceInfo(props) { // eslint-disable-line max-lines-per-function
    const providerUrl = `${providersUrls[props.resource.origin][0]}${props.resource.url}`;
    const tmdbUrl = `${DOWNLOAD_HUB_CORRECTOR_TMDB_API.search}${encodeURIComponent(props.failedQuery?.query || props.resource.name)}`;
    const updatedDiff = Date.now() - Date.parse(props.resource.updated);

    let updatedColor = null;

    if (updatedDiff < 86400000) { // 86400000 ms = 1 day
        updatedColor = 'colorRed';
    } else if (updatedDiff < 604800000) { // 604800000 ms = 1 week
        updatedColor = 'colorYellow';
    } else if (updatedDiff < 2592000000) { // 2592000000 ms = 1 month
        updatedColor = 'colorGreen';
    }

    return (
        <div className='contentResourceInfo'>
            <span><p>{props.resource._id}</p></span>

            <span><p>{props.failedQuery?._id || '-'}</p></span>

            <select name='lang' defaultValue={props.lang} onChange={props.onChange}>
                {[...new Set(Object.values(providersLang))].sort().map((key) => <option key={key} value={key}>{key}</option>)}
            </select>

            <div>
                <h2>{'Title'}</h2>

                <p>{props.resource.name}</p>
            </div>

            <div>
                <h2>{'Origin'}</h2>

                <p>{props.resource.origin}</p>
            </div>

            <div>
                <h2>{'Type'}</h2>

                <p>{props.resource.type === 'movie' ? 'Movie' : 'Series'}</p>
            </div>

            <div>
                <h2>{'URL'}</h2>

                <a href={providerUrl} target='_blank' rel='noopener noreferrer'>{providerUrl}</a>
            </div>

            <div>
                <h2>{'Updated'}</h2>

                <p className={updatedColor}>{new Date(props.resource.updated).toGMTString()}</p>
            </div>

            <div>
                <h2>{'Deletion Alert'}</h2>

                <p className={props.resource.deletionAlert && 'colorRed'}>
                    {props.resource.deletionAlert ? new Date(props.resource.deletionAlert).toGMTString() : '-'}
                </p>
            </div>

            <div>
                <h2>{'Failed Query'}</h2>

                <p>{props.failedQuery?.query || '-'}</p>
            </div>

            <div>
                <h2>{'TMDB URL'}</h2>

                <a href={tmdbUrl} target='_blank' rel='noopener noreferrer'>{tmdbUrl || '-'}</a>
            </div>
        </div>
    );
}

ContentResourceInfo.propTypes = {
    failedQuery: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        query: PropTypes.string.isRequired
    }),
    lang: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    resource: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        deletionAlert: PropTypes.string,
        name: PropTypes.string.isRequired,
        origin: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        updated: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
};

ContentResourceInfo.defaultProps = {
    failedQuery: null
};

export default ContentResourceInfo;