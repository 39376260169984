import './ContentInfo.scss';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This component renders a container with information of the current request and response.
 */
function ContentInfo(props) {
    return (
        <div className='contentInfo'>
            <form autoCapitalize='off' autoComplete='off' onSubmit={props.onSubmit}>
                <h2>{'Config'}</h2>

                <div>
                    <label>{'Page:'}</label>

                    <input type='number' name='page' value={props.config.page} min={0} max={499} size={3} tabIndex={1}
                        onChange={props.onInputChange} />
                </div>

                <div>
                    <label>{'Type:'}</label>

                    <select name='type' defaultValue={props.config.type} onChange={props.onInputChange}>
                        <option value=''>{'All'}</option>

                        <option value='movie'>{'Movie'}</option>

                        <option value='tv'>{'TV'}</option>
                    </select>
                </div>

                <input type='submit' value='Search' />
            </form>

            <p>{`${props.counter} resources`}</p>
        </div>
    );
}

ContentInfo.propTypes = {
    config: PropTypes.shape({
        page: PropTypes.number.isRequired,
        type: PropTypes.string
    }).isRequired,
    counter: PropTypes.number.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ContentInfo;