import './ContentResource.scss';
import { AiOutlineLoading3Quarters, AiOutlineReload } from 'react-icons/ai';
import ContentResourceInfo from './ContentResourceInfo';
import ContentResourceMedia from './ContentResourceMedia';
import { getProvidersProps } from 'dhub-config';
import PropTypes from 'prop-types';
import React from 'react';

const providersLang = getProvidersProps(['lang']);

/**
 * This component renders a container with an instance of ContentResourceInfo (response info) and multiple ContentResourceMedia
 * (resource media) and allows to confirm and send to the server the selected media or the replacement query.
 */
class ContentResource extends React.Component {
    static propTypes = {
        authToken: PropTypes.string.isRequired,
        resource: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            origin: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            updated: PropTypes.string.isRequired
        }).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            correctMedia: new Set(),
            error: null,
            failedQuery: null,
            fetching: false,
            lang: providersLang[this.props.resource.origin] || DOWNLOAD_HUB_CORRECTOR_PROVIDERS_DEFAULT_LANG,
            media: [],
            page: 0,
            replacement: null,
            result: null
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLangChange = this.handleLangChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReload = this.handleReload.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }


    componentDidMount() {
        this.fetchData();
    }

    handleMediaClick(event) {
        const { correctMedia } = this.state;
        const value = parseInt(event.target.value, 10);

        if (correctMedia.has(value)) {
            correctMedia.delete(value);
        } else {
            correctMedia.add(value);
        }

        if (correctMedia.size === 1) {
            const correctMediaId = correctMedia.values().next().value;
            const media = this.state.media.find(({ id }) => id === correctMediaId); // eslint-disable-line react/no-access-state-in-setstate

            this.setState({
                correctMedia,
                replacement: media && `${media.name || media.title}${media.media_type === this.props.resource.type ? '' : ` ([${media.media_type}])`}`
            });
        } else {
            this.setState({ correctMedia, replacement: null });
        }
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.name === 'correctMedia'
            ? new Set(event.target.value.replace(/(?:\s*,\s*)|\s+/gu, ',').split(',').map((id) => parseInt(id, 10)).filter((id) => !isNaN(id)))
            : event.target.value.trim() });
    }

    async handleLangChange(event) {
        this.setState({ lang: event.target.value });

        await this.fetchData();

        if (this.state.correctMedia.size === 1) {
            const correctMediaId = this.state.correctMedia.values().next().value;
            const { name, media_type: type, title } = this.state.media.find(({ id }) => id === correctMediaId);

            this.setState({ replacement: `${name || title}${type === this.props.resource.type ? '' : ` ([${type}])`}` });
        }
    }

    async handleSubmit(event) { // eslint-disable-line max-lines-per-function
        event.preventDefault();

        const target = event.target.name === 'correctMedia';

        if (target ? !this.state.correctMedia.size : !this.state.replacement) {
            return;
        }

        // Check that all the correct medias have the same type as the resource (manually typed ids bypass this check)
        if (target && [...this.state.correctMedia].reduce((acc, correctMediaId) => {
            const media = this.state.media.find(({ id }) => id === correctMediaId);

            if (acc) {
                return acc;
            }

            return media ? media.media_type !== this.props.resource.type : false;
        }, false)) {
            return;
        }

        if (!target && this.state.replacement.trim().toLowerCase() === this.state.failedQuery.query.trim().toLowerCase()) {
            return;
        }

        const msg = target
            ? 'This action will set the selected media as the new related manual media and delete the related failed query.\n'
                + 'Are you sure you want to continue?\n\n'
                + `Resource ID: ${this.props.resource._id}\n`
                + `Failed query ID: ${this.state.failedQuery?._id || '-'}\n\n`
                + `Selected media: ${[...this.state.correctMedia].join(', ')}\n\n`
            : 'This action will set the failed query replacement and delete the related resource.\n'
                + 'Are you sure you want to continue?\n\n'
                + `Failed query ID: ${this.state.failedQuery._id}\n`
                + `Resource ID: ${this.props.resource._id}\n\n`
                + `Replacement: ${this.state.replacement}\n\n`;

        if (window.confirm(msg)) { // eslint-disable-line no-alert
            this.setState({ error: null, fetching: true });

            try {
                await fetch(`${DOWNLOAD_HUB_CORRECTOR_SERVER_URL}${DOWNLOAD_HUB_CORRECTOR_SERVER_API[target ? 'resources' : 'failedQueries']}`, {
                    body: JSON.stringify({
                        id: target ? this.props.resource._id : this.state.failedQuery._id,
                        [target ? 'related' : 'replacement']: target ? [...this.state.correctMedia] : this.state.replacement
                    }),
                    headers: {
                        'Authorization': `Bearer ${this.props.authToken}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'PUT',
                    timeout: DOWNLOAD_HUB_CORRECTOR_REQ_TIMEOUT
                });

                if (!target || (target && this.state.failedQuery?._id)) {
                    await fetch(`${DOWNLOAD_HUB_CORRECTOR_SERVER_URL}${DOWNLOAD_HUB_CORRECTOR_SERVER_API[target ? 'failedQueries' : 'resources']}`, {
                        body: JSON.stringify({
                            id: target ? this.state.failedQuery._id : this.props.resource._id
                        }),
                        headers: {
                            'Authorization': `Bearer ${this.props.authToken}`,
                            'Content-Type': 'application/json'
                        },
                        method: 'DELETE',
                        timeout: DOWNLOAD_HUB_CORRECTOR_REQ_TIMEOUT
                    });
                }

                this.setState((prevState) => ({
                    fetching: false,
                    result: target
                        ? `[${[...prevState.correctMedia].join(', ')}] was set as the new related manual media `
                            + 'and the related failed query was deleted.'
                        : `"${prevState.replacement}" was set as the failed query replacement `
                            + 'and the related resource was deleted.'
                }));
            } catch ({ message: error }) {
                this.setState({ error, fetching: false });
            }
        }
    }

    handleReload() {
        if (this.state.failedQuery) {
            this.setState({ failedQuery: null, media: [] });
            this.fetchData();
        } else {
            const date = new Date(this.props.resource.updated);
            date.setMilliseconds(date.getMilliseconds() - 1);

            this.props.resource.updated = date.toISOString();

            this.setState({ media: [] });
            this.fetchData();
        }
    }

    async fetchData() {
        this.setState({ error: null, fetching: true });

        try {
            const failedQuery = await (await fetch(`${DOWNLOAD_HUB_CORRECTOR_SERVER_URL}${DOWNLOAD_HUB_CORRECTOR_SERVER_API.failedQueries}`
            + `?updated=${encodeURIComponent(this.props.resource.updated)}`, {
                headers: {
                    Authorization: `Bearer ${this.props.authToken}`
                },
                timeout: DOWNLOAD_HUB_CORRECTOR_REQ_TIMEOUT
            })).json();

            const searchQuery = (this.props.resource.name.toLowerCase().includes(failedQuery?.query) ? failedQuery.query : this.props.resource.name)
                .replace(/\s*(?:specials?|ovas?|\(\d{4}\)|\[.+?\]|temporada\s\d|season\s\d)/giu, '');

            const matches = await (await fetch(`${DOWNLOAD_HUB_CORRECTOR_SERVER_URL}${DOWNLOAD_HUB_CORRECTOR_SERVER_API.failedQueriesMatches}`
            + `?query=${encodeURIComponent(searchQuery)}`
            + `${this.state.page ? `&page=${encodeURIComponent(this.state.page)}` : ''}` // eslint-disable-line react/no-access-state-in-setstate
            + `${this.state.lang ? `&lang=${encodeURIComponent(this.state.lang)}` : ''}`, { // eslint-disable-line react/no-access-state-in-setstate
                headers: {
                    Authorization: `Bearer ${this.props.authToken}`
                },
                timeout: DOWNLOAD_HUB_CORRECTOR_REQ_TIMEOUT
            })).json();

            this.setState({ failedQuery, fetching: false, media: matches.results });
        } catch ({ message: error }) {
            this.setState({ error, fetching: false });
        }
    }


    render() { // eslint-disable-line max-lines-per-function
        return (
            <div className='contentResource'>
                <ContentResourceInfo failedQuery={this.state.failedQuery} lang={this.state.lang} resource={this.props.resource}
                    onChange={this.handleLangChange} />

                {this.state.fetching && <AiOutlineLoading3Quarters />}

                {
                    this.state.error && (
                        <div className='contentResourceErr'>
                            <p className='colorRed'>{this.state.error}</p>
                        </div>
                    )
                }

                {
                    this.state.result
                        ? (
                            <div className='contentResourceRes'>
                                <p>{this.state.result}</p>
                            </div>
                        )
                        : (
                            <>
                                <div className='contentResourceForms'>
                                    <form name='correctMedia' autoCapitalize='off' autoComplete='off' onSubmit={this.handleSubmit}>
                                        <h2>{'Correct Media'}</h2>

                                        <input type='text' name='correctMedia' value={[...this.state.correctMedia].join(', ')}
                                            style={{ width: this.state.correctMedia.size
                                                ? `${[...this.state.correctMedia].join(', ').length * 0.9}ch`
                                                : '10rem' }}
                                            pattern='^(?:\d+,\s)*(?:\d+)$' onChange={this.handleInputChange} />

                                        <input type='submit' value='Confirm' />
                                    </form>

                                    {
                                        this.state.failedQuery?._id && (
                                            <form name='replacement' autoCapitalize='off' autoComplete='off' onSubmit={this.handleSubmit}>
                                                <h2>{'Replacement'}</h2>

                                                <input type='text' name='replacement' value={this.state.replacement || ''} pattern='^.{2,200}$'
                                                    style={{ width: this.state.replacement ? `${this.state.replacement.length * 0.9}ch` : '10rem' }}
                                                    onChange={this.handleInputChange} />

                                                <input type='submit' value='Confirm' />
                                            </form>
                                        )
                                    }

                                    {<AiOutlineReload onClick={this.handleReload} />}
                                </div>

                                {this.state.media.length
                                    ? (
                                        <div>
                                            {this.state.media.map((match) => (
                                                <ContentResourceMedia key={match.id} media={match} checked={this.state.correctMedia.has(match.id)}
                                                    onMediaClick={this.handleMediaClick} />
                                            ))}
                                        </div>
                                    )
                                    : !this.state.fetching && (
                                        <div className='contentResourceMsg'>
                                            <p className='state'>{'No Matches'}</p>
                                        </div>
                                    )}
                            </>
                        )
                }
            </div>
        );
    }
}

export default ContentResource;