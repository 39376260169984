import './ContentResourceMedia.scss';
import { FaCheck, FaRegImage } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This component renders a container with information of a resource related media.
 */
function ContentResourceMedia(props) {
    const originalTitle = props.media.original_title || props.media.original_name;
    const title = props.media.title || props.media.name;

    return (
        <div className='contentResourceMedia'>
            {props.media.poster_path
                ? <img src={`${DOWNLOAD_HUB_CORRECTOR_TMDB_API.posterImg}${props.media.poster_path}`} alt='' />
                : <FaRegImage />}

            <div>
                {title && <h1>{`${title}${originalTitle && originalTitle !== title ? ` (${originalTitle})` : ''}`}</h1>}

                {props.media.overview && <p title={props.media.overview}>{props.media.overview}</p>}

                {props.media.media_type && <p>{`Type: ${props.media.media_type === 'movie' ? 'Movie' : 'Series'}`}</p>}

                {props.media.release_date && <p>{`Release Date: ${new Date(props.media.release_date).toLocaleDateString()}`}</p>}

                {props.media.first_air_date && <p>{`First Air Date: ${new Date(props.media.first_air_date).toLocaleDateString()}`}</p>}

                <a href={`${DOWNLOAD_HUB_CORRECTOR_TMDB_API.portal}${props.media.media_type}/${props.media.id}`}
                    target='_blank' rel='noopener noreferrer'
                >
                    {`URL: ${DOWNLOAD_HUB_CORRECTOR_TMDB_API.portal}${props.media.media_type}/${props.media.id}`}
                </a>
            </div>

            <button type='button' value={props.media.id} onClick={props.onMediaClick}>
                {props.checked ? <MdClose strokeWidth='.15em' /> : <FaCheck />}
            </button>
        </div>
    );
}

ContentResourceMedia.propTypes = { /* eslint-disable camelcase */
    checked: PropTypes.bool.isRequired,
    media: PropTypes.shape({
        first_air_date: PropTypes.string,
        id: PropTypes.number.isRequired,
        media_type: PropTypes.string.isRequired,
        name: PropTypes.string,
        original_name: PropTypes.string,
        original_title: PropTypes.string,
        overview: PropTypes.string,
        poster_path: PropTypes.string,
        release_date: PropTypes.string,
        title: PropTypes.string
    }).isRequired,
    onMediaClick: PropTypes.func.isRequired
}; /* eslint-enable camelcase */

export default ContentResourceMedia;