import './Content.scss';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ContentInfo from './ContentInfo';
import ContentResource from './ContentResource';
import { getProvidersProps } from 'dhub-config';
import PropTypes from 'prop-types';
import React from 'react';

const providersUrls = getProvidersProps(['urls']);

/**
 * This component fetches the resources data and renders a container with an instance of ContentInfo (request and response info) and
 * ContentResource (resource info and media).
 */
class Content extends React.Component {
    static propTypes = {
        authToken: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            fetching: false,
            page: 0,
            resources: [],
            type: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchData();
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.name === 'type' ? event.target.value : parseInt(event.target.value, 10) });
    }

    handleSubmit(event) {
        event.preventDefault();

        window.scrollTo(0, 0);
        this.fetchData();
    }

    fetchData() {
        this.setState({ error: null, fetching: true });

        fetch((`${DOWNLOAD_HUB_CORRECTOR_SERVER_URL}${DOWNLOAD_HUB_CORRECTOR_SERVER_API.resources}`
        + `${this.state.page ? `&page=${encodeURIComponent(this.state.page)}` : ''}`
        + `${this.state.type ? `&type=${encodeURIComponent(this.state.type)}` : ''}`).replace('&', '?'), {
            headers: {
                Authorization: `Bearer ${this.props.authToken}`
            },
            timeout: DOWNLOAD_HUB_CORRECTOR_REQ_TIMEOUT
        }).then((res) => res.json()).then((resources) => this.setState({ fetching: false, resources })).catch((error) => {
            this.setState({ error, fetching: false });
        });
    }


    render() {
        const config = (({ page, type }) => ({ page, type }))(this.state);

        return (
            <div className='content'>
                {this.state.fetching && <AiOutlineLoading3Quarters />}

                <ContentInfo config={config} counter={this.state.resources.length}
                    onInputChange={this.handleInputChange} onSubmit={this.handleSubmit} />

                {this.state.error && <p className='state'>{'Error fetching the data'}</p>}

                {
                    this.state.resources.length
                        ? (
                            <>
                                {this.state.resources.map((resource) => (
                                    <ContentResource key={`${providersUrls[resource.origin][0]}${resource.url}`}
                                        authToken={this.props.authToken} resource={resource} />
                                ))}

                                <button type='button' onClick={this.handleSubmit}>{'Refresh'}</button>
                            </>
                        )
                        : !this.state.fetching && <p className='state'>{'No Resources'}</p>
                }
            </div>
        );
    }
}

export default Content;