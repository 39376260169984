import './App.scss';
import Content from './content/Content';
import { FiSun } from 'react-icons/fi';
import React from 'react';

class App extends React.Component {
    constructor(props) {
        super(props);

        const theme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        document.body.className = theme;

        this.state = {
            authToken: null,
            inputToken: '',
            theme
        };

        this.handleToggleTheme = this.handleToggleTheme.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleToggleTheme() {
        if (this.state.theme === 'light') {
            this.setState({ theme: 'dark' });
            localStorage.setItem('theme', 'dark');
            document.body.className = 'dark';
        } else {
            this.setState({ theme: 'light' });
            localStorage.setItem('theme', 'light');
            document.body.className = 'light';
        }
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState((prevState) => ({ authToken: prevState.inputToken }));
    }

    render() {
        return (
            <>
                <div className='themeToggle' title='Dark Mode' onClick={this.handleToggleTheme}>
                    <FiSun />
                </div>

                <form className='authTokenInput' autoCapitalize='off' autoComplete='off' onSubmit={this.handleSubmit}>
                    <input type='text' name='inputToken' value={this.state.inputToken} pattern={'^[\\w]{24}$'} maxLength={24} size={24}
                        placeholder='Authorization Token' autoFocus onChange={this.handleInputChange} />
                </form>

                {this.state.authToken && <Content authToken={this.state.authToken} />}
            </>
        );
    }
}

export default App;